<template>
  <iq-card>
    <template v-slot:body>
      <b-overlay :show="load">
        <b-card>
          <b-table small :items="farmerInfo" head-variant="light" style="border:1px solid #ddd"></b-table>
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
              <b-row>
                <b-col sm="6">
                  <ValidationProvider name="Complain" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="Complain"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('warehouse_service_performance.complain') }} <span class="text-danger">*</span>
                      </template>
                      <vue-editor
                      id="Complain"
                      useCustomImageHandler
                      v-model="complainData.complain"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></vue-editor>
                      <div class="invalid-feedback d-block" v-show="errors.length">
                      {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="6">
                  <ValidationProvider name="Complain (Bn)" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="Complain_bn"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('warehouse_service_performance.complain_bn') }} <span class="text-danger">*</span>
                      </template>
                      <vue-editor
                      id="Complain_bn"
                      useCustomImageHandler
                      v-model="complainData.complain_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></vue-editor>
                      <div class="invalid-feedback d-block" v-show="errors.length">
                      {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="6">
                  <ValidationProvider name="Date" rules="required">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="date"
                    slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('warehouse_service_performance.complain_date') }} <span class="text-danger">*</span>
                      </template>
                      <flat-pickr class="form-control"
                      id="date"
                      v-model="complainData.complain_date"
                      placeholder="Select Date"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </flat-pickr>
                      <div class="invalid-feedback d-block" v-show="errors.length">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="12" class="text-right">
                  <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-overlay>
    </template>
  </iq-card>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { wareFarmerComplainStore } from '../../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'ComplainForm',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    this.getFarmerData()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        farmerInfo: [
            { wareHouseName: '', farmerName: '' }
        ],
        load: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        complainData: {
            division_id: '',
            region_id: '',
            district_id: '',
            upazilla_id: '',
            union_id: '',
            warehouse_id: '',
            complain: '',
            complain_bn: '',
            complain_date: ''
        }
    }
  },
  computed: {
    isBn () {
        return this.$i18n.locale === 'bn'
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    regionLists: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.regionList
    }
  },
  methods: {
    async register () {
        this.load = true
        const result = await RestApi.postData(warehouseServiceBaseUrl, wareFarmerComplainStore, this.complainData)
        if (result.success) {
            this.load = false
            this.$toast.success({
                title: 'Success',
                message: 'Data save successfully',
                color: '#D6E09B'
            })
            this.$bvModal.hide('modal-4')
            this.$emit('data-saved')
        } else {
            this.load = false
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
            })
        }
    },
    async getWarehouseName (warehouseId) {
        const response = await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-info/list-info', { warehouse_id: warehouseId })
        if (response.success) {
            return this.isBn ? response.data.warehouse_name_bn : response.data.warehouse_name
        } else {
            return 'N/A'
        }
    },
    getFarmerData () {
        this.load = true
        try {
            const response = this.$store.state.ExternalUserAuth.farmerUserDetails
            const data = response.user_detail_farmer
            this.complainData.division_id = data.division_id
            this.complainData.district_id = data.district_id
            this.complainData.upazilla_id = data.upazilla_id
            this.complainData.union_id = data.union_id
            this.complainData.region_id = data.region_id
            this.complainData.warehouse_id = data.warehouse_id
            this.farmerInfo[0].farmerName = this.isBn ? response.name_bn : response.name
            this.getWarehouseName(data.warehouse_id).then((name) => {
                this.farmerInfo[0].wareHouseName = name
            })
            this.load = false
        } catch (e) {
            this.load = false
        }
    }
  }
}
</script>
