<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col sm="4">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_config.start_from')"
              label-for="date"
            >
              <flat-pickr class="form-control"
                v-model="search.from_date"
                :config="{}"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_config.end_date')"
              label-for="to_date"
            >
              <flat-pickr class="form-control"
                v-model="search.to_date"
                :config="{}"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('warehouse_service_performance.complain') }} </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="complainDataList" :fields="columns" :aria-hidden="load" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">{{ data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(region_name)="data">
                      <span class="capitalize">{{ data.item.region_name }}</span>
                    </template>
                    <template v-slot:cell(distict_name)="data">
                      <span class="capitalize">{{ data.item.distict_name }}</span>
                    </template>
                    <template v-slot:cell(upzila_name)="data">
                      <span class="capitalize">{{ data.item.upzila_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_name)="data">
                      <span class="capitalize">{{ data.item.farmer_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_land_area)="data">
                      <span>{{ $n(data.item.farmer_land_area) }}</span>
                    </template>
                    <template v-slot:cell(complain_date)="data">
                      <span class="capitalize">{{ data.item.complain_date|dateFormat }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <p v-if="(itemStatus(data.item) === 1)">
                        Pending
                      </p>
                      <p v-if="(itemStatus(data.item) === 2)">
                        Resolve Complain
                      </p>
                    </template>
                    <!-- <template v-slot:cell(action)="data">
                     <b-button v-b-modal.modal-7 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    </template> -->
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
      <b-modal id="modal-7" size="lg" :title="$t('warehouse_service.farmer_request_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Detail :id="testId" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId" @data-saved="testClick()"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { wareFarmerComplainList, userStatus } from '../../../api/routes'
import Detail from './Details'

export default {
  name: 'ComplainList',
  components: {
    FormV, Detail
  },
  data () {
    return {
      load: true,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.ExternalUserIrrigation.static.perPage,
        slOffset: 1
      },
      search: {
        warehouse_name: '',
        warehouse_type_id: 0,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        from_date: '',
        to_date: ''
      },
      testId: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      currentFarmer: [],
      complainDataList: []
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouse_service_performance.complain') : this.$t('warehouse_service_performance.complain')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.division'), class: 'text-left' },
          { label: this.$t('warehouse_config.region'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.farmer_name'), class: 'text-left' },
         { label: this.$t('warehouse_service_performance.land_area'), class: 'text-left' },
         { label: this.$t('warehouse_service_performance.date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' }
          // { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'region_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_name_bn' },
          { key: 'farmer_land_area' },
          { key: 'complain_date' },
          { key: 'status' }
          // { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouse_name' },
          { key: 'farmer_name' },
          { key: 'farmer_land_area' },
          { key: 'complain_date' },
          { key: 'status' }
          // { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList
    }
  },
  created () {
    this.checkApprovedUser()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_name: '',
        warehouse_name_bn: '',
        file_name: '',
        file_name_bn: '',
        total_capacity: '',
        warehouse_type_id: '',
        remarks: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    loadData () {
        this.load = true
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        RestApi.getData(authServiceBaseUrl, `/user/farmer-profile/${this.$store.state.Auth.authUser.username}`).then(response => {
        if (response.success) {
            this.currentFarmer = response.data
            RestApi.getData(warehouseServiceBaseUrl, wareFarmerComplainList, params).then(response => {
                if (response.success) {
                    this.complainDataList = this.ListShow(response.data.data)
                    this.paginationData(response.data)
                }
                this.load = false
            }).catch(() => {
                this.load = false
            })
            } else {
                this.load = false
            }
        })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList

      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
     getRegionDistrictList (regionId = null) {
        const list = this.$store.state.ExternalUserIrrigation.regionDistrictList
        const dataObject = list.filter(document => document.region_id === regionId)
        this.regionDistrictLists = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.district_id, text: this.districtListBn[obj.district_id] }
            } else {
                return { value: obj.district_id, text: this.districtList[obj.district_id] }
            }
        })
    },
   ListShow (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      const listData = data.map(item => {
        tmpData = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(orgItem => orgItem.value === item.division_id)
        tmpData2 = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(orgItem => orgItem.value === item.district_id)
        tmpUpzila = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(orgItem => orgItem.value === item.upazilla_id)
        const orgData = {
            division_name: tmpData.text,
            division_name_bn: tmpData.text_bn,
            distict_name: tmpData2.text,
            distict_name_bn: tmpData2.text_bn,
            upzila_name: tmpUpzila.text,
            upzila_name_bn: tmpUpzila.text_bn,
            farmer_name: this.currentFarmer.name,
            farmer_name_bn: this.currentFarmer.name_bn,
            farmer_land_area: this.currentFarmer.land_area
            }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    itemStatus (item) {
      return item.status
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
            this.loadData()
        } else {
            this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
